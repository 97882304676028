import axios from "axios";

const getToken = () => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  return userToken?.accessToken;
};
const getRefreshToken = () => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  return userToken?.refreshToken;
};

const saveToken = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

const refreshAccessToken = () => {
  const token = getRefreshToken();
  delete axios.defaults.headers.common["Authorization"];
  return axios.post("auth/refresh", {
    token: token,
  });
};

axios.defaults.baseURL = "https://server.verbit.ro/";

axios.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token && config.url !== "auth/refresh") {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.config.url === "auth/refresh" &&
      error.response.status !== 200
    ) {
      localStorage.removeItem("token");
      window.location.reload();
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessToken()
        .then((res) => {
          saveToken(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
      const token = getToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axios;
