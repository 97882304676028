import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '../App/api';

import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText, CInvalidFeedback,
    CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useDispatch } from 'react-redux'

async function loginUser(credentials, token, setToken, setUnauthorized, dispatch) {
    let data = {
        username: credentials.username,
        password: credentials.password,
        application: {
            name: "TRADALMA"
        }
    }
    setUnauthorized(false);
    return api.post('auth/login', data)
    .then(res => {
        let response = res.data;
        dispatch({type: 'set', role: response.role.name });
        setToken(response);
    })
    .catch(e => {
        console.log(e.response.status);
        setUnauthorized(true);
    })
}

function Login({token, setToken}) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [unauthorized, setUnauthorized] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = async e => {
        e.preventDefault();
        loginUser({
            username,
            password
        }, token, setToken, setUnauthorized, dispatch)
            .then(res => {
                //console.log(res)
            });
    }

    return(
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput type="text"
                                                    placeholder="Username"
                                                    autoComplete="username"
                                                    onChange={e => setUserName(e.target.value)}
                                                    invalid={unauthorized}/>
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput type="password"
                                                    placeholder="Password"
                                                    autoComplete="current-password"
                                                    onChange={e => setPassword(e.target.value)}
                                                    invalid={unauthorized}/>
                                            <CInvalidFeedback>Combinatia username/parola este gresita!</CInvalidFeedback>
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton color="primary" className="px-4" onClick={handleSubmit}>Login</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                                <CCardBody className="text-center">
                                    <div>
                                        <img alt="logo" src={'/logo-large.png'} height="150" className={'logo-large'}/>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;