import "./App.css";
import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import useToken from "./components/App/useToken";
import useInterval from "./components/App/useInterval";
import { useDispatch } from "react-redux";

import api from "./components/App/api";
import Circle from "./components/Loaders/Circle";

import "./scss/style.scss";

const Layout = React.lazy(() => import("./components/App/Layout"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const { token, setToken } = useToken();
  const [appLocales, setAppLocales] = useState(null);
  const dispatch = useDispatch();

  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else {
    Notification.requestPermission();
  }

  const checkNews = async () => {
    if (token) {
      // Set notification history in localStorage
      const notifications = localStorage.getItem("notifications");
      let notificationsStorage = {
        last: {
          orders: [],
          contactRequests: [],
        },
        now: {
          orders: [],
          contactRequests: [],
        },
      };

      if (notifications) {
        notificationsStorage = JSON.parse(notifications);
      } else {
        localStorage.setItem(
          "notifications",
          JSON.stringify(notificationsStorage)
        );
      }

      api
        .get("/notification?lastMinutes=6")
        .then((res) => {
          let response = res.data;

          // filter out the already notified items!
          if (response.contactRequests.length) {
            response.contactRequests = response.contactRequests.filter(
              function (msgId) {
                return notificationsStorage.last.contactRequests.indexOf(
                  msgId
                ) >= 0
                  ? false
                  : true;
              }
            );
          }

          if (response.orders.length) {
            response.orders = response.orders.filter(function (orderId) {
              return notificationsStorage.last.orders.indexOf(orderId) >= 0
                ? false
                : true;
            });
          }

          notificationsStorage.now = response;
          localStorage.setItem(
            "notifications",
            JSON.stringify(notificationsStorage)
          );

          let options,
            notificationTitle = "Tradalma - Admin",
            msgNotification = "1 mesaj nou primit",
            ordersNotification = "Comanda noua primita";

          if (response.contactRequests.length && response.orders.length) {
            if (response.contactRequests.length > 1) {
              msgNotification =
                response.contactRequests.length + " mesaje primite";
            }
            if (response.orders.length > 1) {
              ordersNotification = response.orders.length + " comenzi noi";
            } else {
              ordersNotification = "Comanda noua";
            }
            options = {
              body: ordersNotification + " si " + msgNotification + "!",
            };
          } else if (response.contactRequests.length) {
            if (response.contactRequests.length > 1) {
              msgNotification =
                response.contactRequests.length + " mesaje primite";
            }
            options = {
              body: msgNotification + "!",
            };
          } else if (response.orders.length) {
            if (response.orders.length > 1) {
              ordersNotification = response.orders.length + " comenzi noi";
            } else {
              ordersNotification = "Comanda noua";
            }
            options = {
              body: ordersNotification + "!",
            };
          }

          if (options) {
            new Notification(
              notificationTitle,
              Object.assign(
                {
                  body: "",
                  icon: "http://localhost:3000/logo-small.png", //!!! TODO Change
                  dir: "ltr",
                  requireInteraction: true,
                },
                options
              )
            );

            notificationsStorage.last = response;
            localStorage.setItem(
              "notifications",
              JSON.stringify(notificationsStorage)
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useInterval(checkNews, 300000);

  function setDefaultLanguage(appLocales) {
    appLocales.forEach((appLocale) => {
      if (appLocale.default) {
        dispatch({ type: "set", language: appLocale.locale });
      }
    });
  }

  function fetchSettings() {
    //TODO move to diff function and add diff api call for diff settings
    return api
      .get("app-locale")
      .then((appLocales) => {
        dispatch({ type: "set", appLocales: appLocales.data });
        setDefaultLanguage(appLocales.data);
        setAppLocales(appLocales.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  if (!token) {
    return <Login token={token} setToken={setToken} />;
  }

  checkNews()
    .then(() => {})
    .catch((e) => {
      console.log(e);
    });

  if (appLocales === null) {
    return <Circle></Circle>;
  }

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/">
            <Layout setToken={setToken} />
          </Route>
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
