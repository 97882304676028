import React from 'react';

function Circle(params) {

    return(
        <div className={'loader-container '+params.custom}>
            <div className="loader">
            </div>
        </div>
    );
}

export default Circle;